import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate, useLocation } from "react-router-dom";
import CredentialCard from './pages/CredentialCard';
import Navbar from './Components/Navbar2';
import Sales from './pages/Sales';
import CaseStudy from './pages/CaseStudy';
import Service from './Components/Service';
import Footer from './Components/Footer';
import Blog from './pages/Blog';
import ScrollToTop from './pages/ScrollToTop';
import Backdrop from "./adminPanelSRC/components/Backdrop/Backdrop";
import Layout from "./adminPanelSRC/components/Layout/Layout";
import Toolbar from "./adminPanelSRC/components/Toolbar/Toolbar";
import MainNavigation from "./adminPanelSRC/components/Navigation/MainNavigation/MainNavigation";
import MobileNavigation from "./adminPanelSRC/components/Navigation/MobileNavigation/MobileNavigation";
import FeedPage from "./adminPanelSRC/pages/Feed/BlogEditor";
import TestimonialAdmin from "./adminPanelSRC/pages/Feed/Testimonial";
import LoginPage from "./adminPanelSRC/pages/Auth/Login";
import ServiceAdmin from "./adminPanelSRC/pages/Feed/Service";
import BlogAdmin from "./adminPanelSRC/pages/Feed/Blog";
import AreaOfExpertiseAdmin from "./adminPanelSRC/pages/Feed/AreaOfExpertise";
import CaseStudyAdmin from "./adminPanelSRC/pages/Feed/CaseStudy";
import ContactUsAdmin from "./adminPanelSRC/pages/Feed/ContactUs";
import GetPublicUrlOfImage from './adminPanelSRC/pages/Feed/GetPublicUrlOfImages';
import MainPage from "./MainPage";
import Achievements from "./adminPanelSRC/pages/Feed/Achievements";
import Leets from "./adminPanelSRC/pages/Feed/Leets";
import About from "./Components/About";
import BlogD from "./pages/BlogD";
import AdminPageOfHRM from "./HRMPanel/AdminPageOfHRM";
import LoginForm_forHrm from "./HRMPanel/LoginForm_forHrm";
import Cookies from "universal-cookie";
import TimeCard from "./HRMPanel/TimeCard";
import LeaveForm from "./HRMPanel/LeaveForm";
import KanbanMainPage from "./HRMPanel/Kanban/KanbanMainPage";
import TaskDetailView from "./HRMPanel/TaskDetailView";
import TaskDetailForNewWindow from "./HRMPanel/TaskDetailForNewWindow";
import EmployeeManagement from "./adminPanelSRC/pages/Feed/EmployeeManagement";
/*
*********************************************************************************************************
*  @Parent Component           :    App 
*  @React Component Name       :    App
*  @Author                     :    <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*  @Company                    :    Antrazal
*  @Date                       :    05-07-2024
* 
*******************************************************************************************************
* Developer                                             TITLE
* <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)       Url and routing Component 
********************************************************************************************************
*/
function App() {
	const [showBackdrop, setShowBackdrop] = useState(false);
	const [showMobileNav, setShowMobileNav] = useState(false);
	const [isAuth, setIsAuth] = useState(false);
	const [token, setToken] = useState(null);
	const [userId, setUserId] = useState(null);
	const [authLoading, setAuthLoading] = useState(false);
	const [error, setError] = useState(null);
	const cookies = new Cookies();
	const [isGoogleAuth, setIsGoogleAuth] = useState(false);
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const location = useLocation();
	const [navigationUrl,setNavigatonUrl] = useState(window.location.pathname);
	/*
	*********************************************************************************************************
	*   @React Component Name       :       App
	*   @Method                     :       useEffect
	*   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
	*   @Company                    :       Antrazal
	*   @Description                :       login  chack or for the panel 
	*********************************************************************************************************
	*/
	useEffect(() => {
		const token = localStorage.getItem('token');
		const expiryDate = localStorage.getItem('expiryDate');
		if (token && expiryDate && new Date(expiryDate) > new Date()) {
			const userId = localStorage.getItem('userId');
			const remainingMilliseconds = new Date(expiryDate).getTime() - new Date().getTime();
			setIsAuth(true);
			setToken(token);
			setUserId(userId);
			setAutoLogout(remainingMilliseconds);
		}

	}, []);

	/*
*********************************************************************************************************
*   @React Component Name       :       App
*   @Method                     :       mobileNavHandler
*   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*   @Company                    :       Antrazal
*   @Description                :       mobile navigation of the admin panel 
*********************************************************************************************************
*/

	const mobileNavHandler = (isOpen) => {
		setShowMobileNav(isOpen);
		setShowBackdrop(isOpen);
	};

	/*
*********************************************************************************************************
*   @React Component Name       :       App
*   @Method                     :       backdropClickHandler
*   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*   @Company                    :       Antrazal
*   @Description                :       set the state for the backdrop or navigation on the admin page 
*********************************************************************************************************
*/

	const backdropClickHandler = () => {
		setShowBackdrop(false);
		setShowMobileNav(false);
		setError(null);
	};

	/*
*********************************************************************************************************
*   @React Component Name       :       App
*   @Method                     :       logoutHandler
*   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*   @Company                    :       Antrazal
*   @Description                :       logout handlere for the admin panle page 
*********************************************************************************************************
*/

	const logoutHandler = () => {
		setIsAuth(false);
		setToken(null);
		localStorage.removeItem('token');
		localStorage.removeItem('expiryDate');
		localStorage.removeItem('userId');
	};

	/*
	*********************************************************************************************************
	*   @React Component Name       :       App
	*   @Method                     :       loginHandler
	*   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
	*   @Company                    :       Antrazal
	*   @Description                :       login button handler for admin panel
	*********************************************************************************************************
	*/

	const loginHandler = (event, authData) => {
		event.preventDefault();
		setAuthLoading(true);
		fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/user`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				email: authData.email,
				password: authData.password,
			})
		})
			.then((res) => {
				if (res.status === 422) {
					throw new Error('Validation failed.');
				}
				if (res.status !== 200 && res.status !== 201) {
					throw new Error('Could not authenticate you!');
				}
				return res.json();
			})
			.then((resData) => {
				setIsAuth(true);
				setToken(resData.id);
				setAuthLoading(false);
				setUserId(resData.id);
				localStorage.setItem('token', resData.id);
				localStorage.setItem('userId', resData.id);
				const remainingMilliseconds = 60 * 60 * 1000;
				const expiryDate = new Date(new Date().getTime() + remainingMilliseconds);
				localStorage.setItem('expiryDate', expiryDate.toISOString());
				setAutoLogout(remainingMilliseconds);
			})
			.catch((err) => {
				alert("Your details is unauthorized", err.message);
				console.log(err);
				setIsAuth(false);
				setAuthLoading(false);
				setError(err);
			});
	};

	/*
	*********************************************************************************************************
	*   @React Component Name       :       App
	*   @Method                     :       setAutoLogout
	*   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
	*   @Company                    :       Antrazal
	*   @Description                :       Admin page logout handler
	*********************************************************************************************************
	*/

	const setAutoLogout = (milliseconds) => {
		setTimeout(() => {
			logoutHandler();
		}, milliseconds)
	};

	/*
	*********************************************************************************************************
	*   @React Component Name       :       App
	*   @Method                     :       handleLogin
	*   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
	*   @Company                    :       Antrazal
	*   @Description                :       Handel login page if it is true then navigate to the login page 
	*********************************************************************************************************
	*/

	const handleLogin = () => {
		setIsAuthenticated(true);
		if (localStorage.getItem('authTokenForGoogleLogin') === 'true') {
			<Navigate to="/DjUcNcbvQ0PZ9KGjn3t6OEmC7lq5dO0ckborXyZVTnU0Gd5OPJMjpwLKC48QwHAqcXLWVBnXC5fFwMjNzCNGp5C8fcZ9U7friZIp/hrmLoginPage" />
		}
	};

	return (
		<>
			<ScrollToTop />
			{
				isAuth && navigationUrl.startsWith("/DjUcNcbvQ0PZ9KGjn3t6OEmC7lq5dO0ckborXyZVTnU0Gd5OPJMjpwLKC48QwHAqcXLWVBnXC5fFwMjNzCNGp5C8fcZ9U7friZIp/antrazal/admin") ? (
					<Layout
						header={
							<Toolbar>
								<MainNavigation
									onOpenMobileNav={() => mobileNavHandler(true)}
									onLogout={logoutHandler}
									isAuth={isAuth}
								/>
							</Toolbar>
						}
						mobileNav={
							<MobileNavigation
								open={showMobileNav}
								mobile
								onChooseItem={() => mobileNavHandler(false)}
								onLogout={logoutHandler}
								isAuth={isAuth}
							/>
						}
					/>
				) : (
					(isAuth || localStorage.getItem('authTokenForGoogleLogin') == 'true')&& navigationUrl.startsWith("/DjUcNcbvQ0PZ9KGjn3t6OEmC7lq5dO0ckborXyZVTnU0Gd5OPJMjpwLKC48QwHAqcXLWVBnXC5fFwMjNzCNGp5C8fcZ9U7friZIp/adminPanelHrm") ? (
						<>
						</>
					) : (
						<Navbar />
					)
				)
			}
			<Routes>
				
				<Route path="/" element={<MainPage />} />
				<Route path="/credentials" element={<CredentialCard />} />
				<Route path="/services/caseStudy" element={<CaseStudy />} />
				<Route path="/services" element={<Sales />} />
				<Route path="/services" element={<Service />} />
				<Route path="/blog" element={<Blog />} />
				<Route path="/aboutUs" element={<About />} />
				<Route path="/blog/page" element={<BlogD />} />
				<Route path="*" element={<Navigate to="/" />} />
				{/* <Route path="/task-detail/:id" element={<TaskDetailView />} /> */}
				<Route path="/DjUcNcbvQ0PZ9KGjn3t6OEmC7lq5dO0ckborXyZVTnU0Gd5OPJMjpwLKC48QwHAqcXLWVBnXC5fFwMjNzCNGp5C8fcZ9U7friZIp/hrmLoginPage" element={<LoginForm_forHrm onLogin={handleLogin} />} />
				<Route
					path="/DjUcNcbvQ0PZ9KGjn3t6OEmC7lq5dO0ckborXyZVTnU0Gd5OPJMjpwLKC48QwHAqcXLWVBnXC5fFwMjNzCNGp5C8fcZ9U7friZIp/adminPanelHrm"
					element={localStorage.getItem('authTokenForGoogleLogin') === 'true' ? <AdminPageOfHRM /> : <Navigate to="/DjUcNcbvQ0PZ9KGjn3t6OEmC7lq5dO0ckborXyZVTnU0Gd5OPJMjpwLKC48QwHAqcXLWVBnXC5fFwMjNzCNGp5C8fcZ9U7friZIp/hrmLoginPage" />}
				/>
				<Route
					path="/DjUcNcbvQ0PZ9KGjn3t6OEmC7lq5dO0ckborXyZVTnU0Gd5OPJMjpwLKC48QwHAqcXLWVBnXC5fFwMjNzCNGp5C8fcZ9U7friZIp/adminPanelHrm/task-detail/"
					element={localStorage.getItem('authTokenForGoogleLogin') === 'true' ? <TaskDetailForNewWindow /> : <Navigate to="/DjUcNcbvQ0PZ9KGjn3t6OEmC7lq5dO0ckborXyZVTnU0Gd5OPJMjpwLKC48QwHAqcXLWVBnXC5fFwMjNzCNGp5C8fcZ9U7friZIp/hrmLoginPage" />}
				/>
				<Route
					path="/DjUcNcbvQ0PZ9KGjn3t6OEmC7lq5dO0ckborXyZVTnU0Gd5OPJMjpwLKC48QwHAqcXLWVBnXC5fFwMjNzCNGp5C8fcZ9U7friZIp/adminPanelHrm/timeCard"
					element={localStorage.getItem('authTokenForGoogleLogin') === 'true' ? <TimeCard /> : <Navigate to="/DjUcNcbvQ0PZ9KGjn3t6OEmC7lq5dO0ckborXyZVTnU0Gd5OPJMjpwLKC48QwHAqcXLWVBnXC5fFwMjNzCNGp5C8fcZ9U7friZIp/hrmLoginPage" />}
				/>
				<Route
					path="/DjUcNcbvQ0PZ9KGjn3t6OEmC7lq5dO0ckborXyZVTnU0Gd5OPJMjpwLKC48QwHAqcXLWVBnXC5fFwMjNzCNGp5C8fcZ9U7friZIp/adminPanelHrm/leaveform"
					element={localStorage.getItem('authTokenForGoogleLogin') === 'true' ? <LeaveForm /> : <Navigate to="/DjUcNcbvQ0PZ9KGjn3t6OEmC7lq5dO0ckborXyZVTnU0Gd5OPJMjpwLKC48QwHAqcXLWVBnXC5fFwMjNzCNGp5C8fcZ9U7friZIp/hrmLoginPage" />}
				/>
				<Route
					path="/DjUcNcbvQ0PZ9KGjn3t6OEmC7lq5dO0ckborXyZVTnU0Gd5OPJMjpwLKC48QwHAqcXLWVBnXC5fFwMjNzCNGp5C8fcZ9U7friZIp/adminPanelHrm/Canban"
					element={localStorage.getItem('authTokenForGoogleLogin') === 'true' ? <KanbanMainPage /> : <Navigate to="/DjUcNcbvQ0PZ9KGjn3t6OEmC7lq5dO0ckborXyZVTnU0Gd5OPJMjpwLKC48QwHAqcXLWVBnXC5fFwMjNzCNGp5C8fcZ9U7friZIp/hrmLoginPage" />}
				/>
				{showBackdrop && <Backdrop onClick={backdropClickHandler} />}
				<Route
					path="/DjUcNcbvQ0PZ9KGjn3t6OEmC7lq5dO0ckborXyZVTnU0Gd5OPJMjpwLKC48QwHAqcXLWVBnXC5fFwMjNzCNGp5C8fcZ9U7friZIp/antrazal/admin/"
					element={
						isAuth ? (
							<>
								<FeedPage userId={userId} token={token} />
							</>
						) : (
							<LoginPage onLogin={loginHandler} loading={authLoading} />
						)
					}
				/>
				<Route
					path="/DjUcNcbvQ0PZ9KGjn3t6OEmC7lq5dO0ckborXyZVTnU0Gd5OPJMjpwLKC48QwHAqcXLWVBnXC5fFwMjNzCNGp5C8fcZ9U7friZIp/antrazal/admin/testimonial"
					element={
						isAuth ? (
							<>
								<TestimonialAdmin />
							</>
						) : (
							<LoginPage onLogin={loginHandler} loading={authLoading} />
						)
					}
				/>
				<Route
					path="/DjUcNcbvQ0PZ9KGjn3t6OEmC7lq5dO0ckborXyZVTnU0Gd5OPJMjpwLKC48QwHAqcXLWVBnXC5fFwMjNzCNGp5C8fcZ9U7friZIp/antrazal/admin/service"
					element={
						isAuth ? (
							<>
								<ServiceAdmin />
							</>
						) : (
							<LoginPage onLogin={loginHandler} loading={authLoading} />
						)
					}
				/>
				<Route
					path="/DjUcNcbvQ0PZ9KGjn3t6OEmC7lq5dO0ckborXyZVTnU0Gd5OPJMjpwLKC48QwHAqcXLWVBnXC5fFwMjNzCNGp5C8fcZ9U7friZIp/antrazal/admin/blog"
					element={
						isAuth ? (
							<>
								<BlogAdmin />
							</>
						) : (
							<LoginPage onLogin={loginHandler} loading={authLoading} />
						)
					}
				/>
				<Route
					path="/DjUcNcbvQ0PZ9KGjn3t6OEmC7lq5dO0ckborXyZVTnU0Gd5OPJMjpwLKC48QwHAqcXLWVBnXC5fFwMjNzCNGp5C8fcZ9U7friZIp/antrazal/admin/employeManagement"
					element={
						isAuth ? (
							<>
								<EmployeeManagement />
							</>
						) : (
							<LoginPage onLogin={loginHandler} loading={authLoading} />
						)
					}
				/>
				<Route
					path="/DjUcNcbvQ0PZ9KGjn3t6OEmC7lq5dO0ckborXyZVTnU0Gd5OPJMjpwLKC48QwHAqcXLWVBnXC5fFwMjNzCNGp5C8fcZ9U7friZIp/antrazal/admin/areasOfOurExpertise"
					element={
						isAuth ? (
							<>
								<AreaOfExpertiseAdmin />
							</>
						) : (
							<LoginPage onLogin={loginHandler} loading={authLoading} />
						)
					}
				/>
				<Route
					path="/DjUcNcbvQ0PZ9KGjn3t6OEmC7lq5dO0ckborXyZVTnU0Gd5OPJMjpwLKC48QwHAqcXLWVBnXC5fFwMjNzCNGp5C8fcZ9U7friZIp/antrazal/admin/caseStudy"
					element={
						isAuth ? (
							<>
								<CaseStudyAdmin />
							</>
						) : (
							<LoginPage onLogin={loginHandler} loading={authLoading} />
						)
					}
				/>
				<Route
					path="/DjUcNcbvQ0PZ9KGjn3t6OEmC7lq5dO0ckborXyZVTnU0Gd5OPJMjpwLKC48QwHAqcXLWVBnXC5fFwMjNzCNGp5C8fcZ9U7friZIp/antrazal/admin/getpublicUrlOfImges"
					element={
						isAuth ? (
							<>
								<GetPublicUrlOfImage />
							</>
						) : (
							<LoginPage onLogin={loginHandler} loading={authLoading} />
						)
					}
				/>
				<Route
					path="/DjUcNcbvQ0PZ9KGjn3t6OEmC7lq5dO0ckborXyZVTnU0Gd5OPJMjpwLKC48QwHAqcXLWVBnXC5fFwMjNzCNGp5C8fcZ9U7friZIp/antrazal/admin/contactUs"
					element={
						isAuth ? (
							<>
								<ContactUsAdmin />
							</>
						) : (
							<LoginPage onLogin={loginHandler} loading={authLoading} />
						)
					}
				/>
				<Route
					path="/DjUcNcbvQ0PZ9KGjn3t6OEmC7lq5dO0ckborXyZVTnU0Gd5OPJMjpwLKC48QwHAqcXLWVBnXC5fFwMjNzCNGp5C8fcZ9U7friZIp/antrazal/admin/achievements"
					element={
						isAuth ? (
							<>
								<Achievements />
							</>
						) : (
							<LoginPage onLogin={loginHandler} loading={authLoading} />
						)
					}
				/>
				<Route
					path="/DjUcNcbvQ0PZ9KGjn3t6OEmC7lq5dO0ckborXyZVTnU0Gd5OPJMjpwLKC48QwHAqcXLWVBnXC5fFwMjNzCNGp5C8fcZ9U7friZIp/antrazal/admin/leets"
					element={
						isAuth ? (
							<>
								<Leets />
							</>
						) : (
							<LoginPage onLogin={loginHandler} loading={authLoading} />
						)
					}
				/>
			</Routes>
			{/* {
				isAuth || (localStorage.getItem('authTokenForGoogleLogin') == 'true' && navigationUrl.startsWith("/DjUcNcbvQ0PZ9KGjn3t6OEmC7lq5dO0ckborXyZVTnU0Gd5OPJMjpwLKC48QwHAqcXLWVBnXC5fFwMjNzCNGp5C8fcZ9U7friZIp/adminPanelHrm")) || navigationUrl.startsWith("/DjUcNcbvQ0PZ9KGjn3t6OEmC7lq5dO0ckborXyZVTnU0Gd5OPJMjpwLKC48QwHAqcXLWVBnXC5fFwMjNzCNGp5C8fcZ9U7friZIp/antrazal/admin")  ? (
					<></>
				) : (
					<Footer />
				)
			} */}
			{
				isAuth && navigationUrl.startsWith("/DjUcNcbvQ0PZ9KGjn3t6OEmC7lq5dO0ckborXyZVTnU0Gd5OPJMjpwLKC48QwHAqcXLWVBnXC5fFwMjNzCNGp5C8fcZ9U7friZIp/antrazal/admin") ? (
					<></>
				) : (
					(isAuth || localStorage.getItem('authTokenForGoogleLogin') == 'true')&& navigationUrl.startsWith("/DjUcNcbvQ0PZ9KGjn3t6OEmC7lq5dO0ckborXyZVTnU0Gd5OPJMjpwLKC48QwHAqcXLWVBnXC5fFwMjNzCNGp5C8fcZ9U7friZIp/adminPanelHrm") ? (
						<>
						</>
					) : (
						<Footer />
					)
				)
			}
		</>
	);
}

export default App;
